<template>
  <div class="experience_order">
    <div class="order_status_nav">
      <div
        :class="[currentOrderType == 2 ? 'active' : '', 'nohandle']"
        @click="changeOrderType(2)"
      >
        <!-- <span v-if="orderNums.no_visit"></span> -->
        全部
      </div>
      <div
        :class="[currentOrderType == 0 ? 'active' : '']"
        @click="changeOrderType(0)"
      >
        未上架
      </div>
      <div
        :class="[currentOrderType == 1 ? 'active' : '']"
        @click="changeOrderType(1)"
      >
        已上架
      </div>
    </div>
    <div class="search_wrap">
      <el-row class="mb-4">
        <el-button class="mb-4-elbtn" type="primary" @click="addfactory"
          >新建云工厂</el-button
        >
        <button class="mb-4-btn" type="default" @click="allAdded">上架</button>
        <button class="mb-4-btn" type="default" @click="allTakeDown">
          下架
        </button>
        <button class="mb-4-btn" type="default" @click="allDel">删除</button>
      </el-row>
      <div>
        <el-input
          placeholder="请输入工厂名称或电话"
          v-model="search_keyword"
          class="input-with-select"
        >
          <el-button @click="searchBtn" slot="append">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="manage_order_list_warp">
      <el-table
        @cell-click="cellClick"
        border
        :data="tableData"
        ref="multipleTableRef"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @filter-change="handleFilterChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="fact_name"
          label="工厂名称"
          width="240"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="rowapper">
              <img :src="scope.row.picture" alt="" class="titleimg" />
              <div class="des">
                <div class="name" @click="handlesee(scope.row)">
                  {{ scope.row.fact_name }}
                </div>
                <div class="addres">{{ scope.row.address }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          column-key="uid"
          prop="uploader"
          label="创建者"
          width="180"
          :filters="creatNameList"
        >
        </el-table-column>
        <el-table-column label="授权区域" property="auth_area">
          <template v-slot="scope">
            <el-input
              ref="tableInput"
              v-model="scope.row.auth_area"
              @blur="removeClass"
              @change="handleEditInput('info', scope.$index, scope.row)"
            ></el-input>
            <!-- <span>{{ scope.row.auth_area }}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="级别" property="level">
          <template v-slot="scope">
            <el-input
              ref="tableInput"
              v-model="scope.row.level"
              @blur="removeClass"
              @change="handleEditInput('info', scope.$index, scope.row)"
            ></el-input>
            <!-- <span>{{ scope.row.level }}</span> -->
          </template>
        </el-table-column>
        <el-table-column
          column-key="is_cloud_factory"
          prop="certification"
          label="工厂类型"
          :filters="certificationFilter"
          :filter-multiple="false"
        >
          <template v-slot="scope">
            {{ scope.row.certification ? '云工厂' : '普通工厂' }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="update_time" label="最后更新时间">
        </el-table-column>
        <el-table-column label="培训机构" property="group_name">
          <template v-slot="scope">
            <span style="color: #155bd4">{{
              scope.row.group_name ? scope.row.group_name : '未申请'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="上架状态">
          <template v-slot="scope">
            <span
              class="cirle"
              :style="
                scope.row.status == 1
                  ? 'background:rgba(0, 168, 112, 1)'
                  : 'background: #ccc'
              "
            ></span>
            <span
              class="upcir"
              :style="
                scope.row.status == 1
                  ? 'color:rgba(0, 168, 112, 1)'
                  : 'color: #ccc'
              "
              >{{ scope.row.status == 0 ? '已下架' : '已上架' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <span class="do_up" @click="handleClick(scope.row)">{{
              scope.row.status == 0 ? '上架' : '下架'
            }}</span>
            <span class="do_edit" @click="handleEdit(scope.$index, scope.row)"
              >编辑</span
            >
            <span
              class="do_del"
              @click="rowDelete(scope.$index, scope.row, tableData)"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="allTakeDownValue" width="25%">
      <div class="alltakedown" style="display: flex">
        <i
          class="el-icon-warning"
          style="font-size: 25px; color: rgb(230, 162, 60)"
        ></i>
        <div style="margin-left: 10px">
          <p
            style="
              font-size: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.9);
              margin-bottom: 10px;
            "
          >
            请确认是否下架已选中的{{ multipleSelection.length }}条数据
          </p>
          <span style="color: rgba(0, 0, 0, 0.6)"
            >点击确认后会直接下架厂家信息</span
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allTakeDownValue = false">取 消</el-button>
        <el-button type="primary" @click="sureAllTakeDown">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="allAddedValue" width="25%">
      <div class="alltakedown" style="display: flex">
        <i
          class="el-icon-warning"
          style="font-size: 25px; color: rgb(230, 162, 60)"
        ></i>
        <div style="margin-left: 10px">
          <p
            style="
              font-size: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.9);
              margin-bottom: 10px;
            "
          >
            请确认是否上架已选中的{{ multipleSelection.length }}条数据
          </p>
          <span style="color: rgba(0, 0, 0, 0.6)">该操作不可恢复</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allAddedValue = false">取 消</el-button>
        <el-button type="primary" @click="sureallAdded">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="allDelValue" width="25%">
      <div class="alltakedown" style="display: flex">
        <i
          class="el-icon-warning"
          style="font-size: 25px; color: rgb(230, 162, 60)"
        ></i>
        <div style="margin-left: 10px">
          <p
            style="
              font-size: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.9);
              margin-bottom: 10px;
            "
          >
            请确认是否上架已选中的{{ multipleSelection.length }}条数据
          </p>
          <span style="color: rgba(0, 0, 0, 0.6)">该操作不可恢复</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allDelValue = false">取 消</el-button>
        <el-button type="primary" @click="sureAllDel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="doSomeThingValue" width="25%">
      <div class="alltakedown" style="display: flex">
        <i
          class="el-icon-warning"
          style="font-size: 25px; color: rgb(230, 162, 60)"
        ></i>
        <div style="margin-left: 10px">
          <p
            style="
              font-size: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.9);
              margin-bottom: 10px;
            "
          >
            请确认是否操作该条数据
          </p>
          <span style="color: rgba(0, 0, 0, 0.6)">该操作不可恢复</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doSomeThingValue = false">取 消</el-button>
        <el-button type="primary" @click="suredoSomeThingValue"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.page"
        :page-sizes="[9, 18, 27, 36]"
        :page-size="form.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total_count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 操作弹窗
      doSomeThingValue: false,
      // 删除弹窗显示
      allDelValue: false,
      // 上架弹窗显示
      allAddedValue: false,
      // 下架弹框显示与不显示
      allTakeDownValue: false,
      // 多选的内容
      multipleSelection: [],
      // 创建者筛选list
      creatNameList: [],
      //工厂类型筛选
      certificationFilter: [
        { text: '云工厂', value: 1 },
        { text: '普通工厂', value: 2 },
      ],
      close: false,
      form: {
        filter: '',
        page: 1,
        limit: 9,
        status: 2,
        is_cloud_factory: 0,
        uid: '',
      },
      currentOrderType: 2,
      tableData: [],
      currentOrderInfo: {},
      total_count: 13,
      search_keyword: '',
      doform: {
        id: '',
        num: '',
        status: '',
      },
    }
  },
  computed: {
    orderNums() {
      return this.$store.state.tatal_order_num.experience || {}
    },
    orderStatusStyle() {
      return (status) => {
        return {
          width: '8px',
          height: '8px',
          'border-radius': '50%',
          backgroundColor: status ? '#00a854' : '#bfbfbf',
          display: 'inline-block',
          marginRight: '5px',
        }
      }
    },
  },
  methods: {
    // 点击 单元格 添加class
    cellClick(row, column, cell, event) {
      console.log(row, column, event)
      for (
        let i = 0;
        i < document.getElementsByClassName('current-cell').length;
        i++
      ) {
        document
          .getElementsByClassName('current-cell')
          [i].classList.remove('current-cell')
      }
      cell.classList.add('current-cell')
    },
    // 移除class
    removeClass() {
      document
        .getElementsByClassName('current-cell')[0]
        .classList.remove('current-cell')
    },
    // 添加信息
    getAddInfo(payload) {
      this.$request.getAddOnfo(payload, (res) => {
        console.log('add成功', res)
      })
    },
    // 点击input 输入
    handleEditInput(name, e, row) {
      console.log('name', name)
      console.log('e', e)
      console.log('row', row)
      let payload = {
        id: row.id,
        auth_area: row.auth_area,
        level: row.level,
      }
      this.getAddInfo(payload)
    },
    // 点击工厂name查看详情
    handlesee(row) {
      console.log('row', row)
      this.$router.push({
        path: 'factory-info',
        query: {
          id: row.id,
        },
      })
    },
    // all下架弹窗确认
    sureAllTakeDown() {
      let idarr = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        idarr.push(this.multipleSelection[i].id)
      }
      this.$request.getManageCloudFact({ id: idarr, status: 0 }, (res) => {
        if (res.status == 1) {
          this.$message.success('下架成功')
          this.allTakeDownValue = false
          this.getList()
        }
      })
    },
    // all下架
    allTakeDown() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择你需要下架的数据')
      } else {
        this.allTakeDownValue = true
      }
    },
    // all上架弹窗确认
    sureallAdded() {
      let idarr = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        idarr.push(this.multipleSelection[i].id)
      }
      this.$request.getManageCloudFact({ id: idarr, status: 1 }, (res) => {
        if (res.status == 1) {
          this.$message.success('上架成功')
          this.allAddedValue = false
          this.getList()
        }
      })
    },
    // all上架
    allAdded() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择你需要上架的数据')
      } else {
        this.allAddedValue = true
      }
    },
    // all删除弹窗确认
    sureAllDel() {
      let idarr = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        idarr.push(this.multipleSelection[i].id)
        if (this.multipleSelection[i].status == 1) {
          this.$message.warning('已上架的不能删除')
          return
        }
      }
      this.$request.getAllDel({ id: idarr }, (res) => {
        if (res.status == 1) {
          this.$message.success('删除成功')
          this.allDelValue = false
          this.getList()
        }
      })
    },
    // all删除
    allDel() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择你需要删除的数据')
      } else {
        this.allDelValue = true
      }
    },
    // 多选框
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('val', val)
    },
    // 表格筛选
    handleFilterChange(value) {
      this.form.page = 1
      for (let key in value) {
        if (key == 'is_cloud_factory') {
          this.form[key] = value.is_cloud_factory[0]
        } else {
          this.form[key] = value[key]
        }
      }
      this.$request.getCloudFactoryList(this.form, (res) => {
        this.total_count = res.data.total
        this.tableData = res.data.data
      })
    },
    // 删除
    getDel(id) {
      let idarr = [id]
      this.$request.getAllDel({ id: idarr }, (res) => {
        if (res.status == 1) {
          this.$message.success('删除成功')
          this.doSomeThingValue = false
          this.getList()
        }
      })
    },
    // 操作确认按钮
    suredoSomeThingValue() {
      if (this.doform.num == 1) {
        this.getDel(this.doform.id)
      }
      if (this.doform.num == 2) {
        let payload = {
          id: [this.doform.id],
          status: this.doform.status == 0 ? 1 : 0,
        }
        this.$request.getManageCloudFact(payload, (res) => {
          if (res.status == 1) {
            this.$message.success(
              this.doform.status == 0 ? '上架成功' : '下架成功'
            )
            this.doSomeThingValue = false
            this.getList()
          }
        })
      }
    },
    // 单个删除
    rowDelete(index, row, tableData) {
      if (row.status == 1) {
        this.$message.warning('已上架的不能删除')
        return
      }
      this.doSomeThingValue = true
      this.doform.id = row.id
      this.doform.num = 1
      console.log('删除index, row, tableData', index, row, tableData)
    },
    // 单个编辑
    handleEdit(index, row) {
      this.$router.push({
        path: 'add-factory',
        query: {
          type: 'edit',
          id: row.id,
        },
      })
      console.log('编辑index,row', index, row)
    },
    // 单个上架-下架
    handleClick(row) {
      this.doSomeThingValue = true
      console.log('上架-下架row', row)
      this.doform.num = 2
      this.doform.id = row.id
      this.doform.status = row.status
    },
    // 新增
    addfactory() {
      this.$router.push({
        path: 'add-factory',
        query: {
          type: 'add',
          status: 1,
        },
      })
    },
    // 搜索按钮
    searchBtn() {
      this.form.filter = this.search_keyword
      this.$request.getCloudFactoryList(this.form, (res) => {
        this.total_count = res.data.total
        this.tableData = res.data.data
      })
    },
    handleSizeChange(value) {
      this.form.limit = value
      this.getList()
    },
    changeOrderType(value) {
      this.search_keyword = ''
      this.form.page = 1
      this.form.status = value
      this.currentOrderType = value
      console.log('value', value)
      this.getList()
    },

    handleCurrentChange(page) {
      this.form.page = page
      if (this.search_keyword) {
        this.searchBtn()
      } else {
        this.getList()
      }
    },
    // 获取表格列表
    getList() {
      this.$request.getCloudFactoryList(this.form, (res) => {
        console.log('云工厂信息获取成功', res)
        this.total_count = res.data.total
        this.tableData = res.data.data
      })
    },
    // 获取创建者
    getCreatList() {
      this.$request.getCreateName({}, (res) => {
        console.log('创建者获取成功', res)
        let filetr = []
        for (let i = 0; i < res.data.length; i++) {
          let obj = {
            text: '',
            value: null,
          }
          obj.text = res.data[i].name
          obj.value = res.data[i].id
          filetr.push(obj)
        }
        this.creatNameList = filetr
      })
    },
  },
  mounted() {
    this.getList()
    this.getCreatList()
  },
}
</script>

<style lang="less" scoped>
.experience_order {
  padding: 12px;
  .order_status_nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    > div {
      position: relative;
      bottom: -1px;
      padding: 12px 24px;
      cursor: pointer;
      user-select: none;
      &.active {
        border-bottom: 2px solid #1890ff;
        color: #1890ff;
      }
    }
    .nohandle {
      position: relative;
      > span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #f5222d;
        position: absolute;
        display: inline-block;
        top: 8px;
        left: 50%;
      }
    }
  }
  .search_wrap {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    /deep/ .el-select .el-input {
      width: 130px;
    }
    /deep/ .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
    /deep/ .el-input-group__append {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
    .mb-4 {
      .mb-4-elbtn {
        color: white;
        background: #6476ffff !important;
        border: none;
      }
      .mb-4-btn {
        margin-left: 10px;
        cursor: pointer;
        background: #ffffff;
        color: #000000 90%;
        font-size: 14px;
        width: 60px;
        height: 32px;
        width: 60px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #dcdcdc;
      }
    }
  }
  .manage_order_list_warp {
    .rowapper {
      display: flex;
      .titleimg {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      .name {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #6476ff;
        line-height: 22px;
      }
      .addres {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
      }
    }
    .do_up,
    .do_edit,
    .do_del {
      margin-right: 5px;
      color: rgb(21, 91, 212);
      cursor: pointer;
    }
    .cirle {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #00a870;
      border-radius: 50%;
      opacity: 1;
      margin-right: 5px;
    }
    .upcir {
      font-size: 14px;
      margin-right: 5px;
      font-weight: 400;
      color: #00a870;
      line-height: 22px;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
  .orderDetalDialog_wrap {
    p {
      margin-bottom: 10px;
    }
  }
  .handleDialog_wrap {
  }
}

/deep/.current-cell /deep/.el-input {
  display: block;
}
</style>
